<template>
  <div class="excel-data-preview">
    <div class="excel-data-preview-wrapper">
        <div class="excel-data-preview-wrapper-header">
            <h1>מידע מקובץ אקסל</h1>
            <div @click="$emit('close')" class="exit-btn">
                <i class="material-icons">close</i>
            </div>
        </div>
        <div class="excel-data-preview-wrapper-content">
           <table>
            <tr>
                <th>ברקוד</th>
                <th>שם עברית</th>
                <th>שם לועזית</th>
                <th>מחיר קטלוגי</th>
                <th>מחלקה</th>
                <th>מידה</th>
            </tr>
            <template v-for="(d) in data" :key="d['ברקוד']">
                <tr>
                    <td>{{d['ברקוד']}}</td>
                    <td>{{d['שם עברית']}}</td>
                    <td>{{d['שם לועזית']}}</td>
                    <td>{{d['מחיר קטלוגי']}}</td>
                    <td>{{d['מחלקה']}}</td>
                    <td>{{d['מידה']}}</td>
                </tr>
            </template>    
           </table>
        </div>
        <div class="excel-data-preview-wrapper-footer">
            <el-button v-if="!is_pending" @click="handle_submit" style="width:100%" type="success">טעינת פריטים</el-button>
            <el-button v-if="is_pending" style="width:100%" type="success" disabled>נא להמתין...</el-button>
        </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
export default {
    props:['data'],
    emits:['close','submit'],
    setup(_,{emit}){
        const is_pending = ref(false)
        const handle_submit = ()=>{
            is_pending.value = true
            emit('submit')
        }

        return{
            handle_submit,
            is_pending,
        }
    }
}
</script>

<style scoped>
    .excel-data-preview{
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
    }
    .excel-data-preview-wrapper{
        width: 100%;
        max-width: 500px;
        height: 70%;
        background: #fff;
        color: #333;
        border-radius: 5px;
    }
    .excel-data-preview-wrapper-header{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
    }
    .exit-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        top: 5px;
        left: 5px;
        z-index: 16;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: #fff;
        background: crimson;
        border-radius: 50%;
        cursor: pointer;
    }
    .excel-data-preview-wrapper-content{
        width: 100%;
        height: calc(100% - 100px);
        overflow: hidden;
        overflow-y: auto;
        padding: 0 5px;
    }
    .excel-data-preview-wrapper-footer{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: var(--purple);
        color: white;
        position: sticky;
        top: 0;
        font-size: 20px;
    }
    
</style>